import React from 'react';
import axios from 'axios';

import Auxi from '../../hoc/Auxi';
import Spinner from '../../components/Widgets/Spinner';
//import AppLayout from '../../containers/Layout/CustomAppTabLayout';
import AppLayout from './CustomAppTabLayout';

import Toast from '../../components/Widgets/CustomToast';

import PullToRefresh from 'react-simple-pull-to-refresh';

import FilterModal from './FilterModal'

import io from 'socket.io-client';

import '../../assets/scss/custom-tab-styles/style.scss';
import { toast } from 'react-toastify';

class CallParkings extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            recordType: 'vmail/new',
            voiceMails: [],
            username: "Daniyal",
            loading: true,
            audioSource: '',
            filterModal: false,
            filteredList:"",
            callList: [],
        };
        this.playPauseRef = [];
    }
    socket = null;
    //onOff = true;
    usersInCall = (uType, userName, password, cloudId) => {
        uType = "s";
        let payLoad = new FormData();
        payLoad.append('domain', userName.split("@")[1]);
        payLoad.append('cloud_id', cloudId);
        
        const url = "?format=json&object=call&action=read&utype=" + uType + "&cloud_password=" +password + "&cloud_username=" + userName;
        this.setState({myCallId : null});
        axios.post(process.env.REACT_APP_API_NS_URL + url, payLoad)
        .then(response=>{
            let callList = []
            // if(this.onOff){
            //     callList = [
            //         {
            //             orig_name : "Daniyal Majeed",
            //             by_user: "200",
            //             by_domain: "Saevolg.ca",
            //             term_user: "9001",
            //             dnis: "X9001", // can make condition on dnis if X exists
            //             term_uri: "Call-Queue", // this could be used to as it would be call-queue for call park else sip:....
            //             term_call_info: "held", // this would be held
            //             orig_call_info: "held" // this would be held to for call parking else active
            //         },
            //         {
            //             orig_name : "Shaighan Khan",
            //             by_user: "130",
            //             by_domain: "Saevolg.ca",
            //             term_user: "9006",
            //             dnis: "X9006", // can make condition on dnis if X exists
            //             term_uri: "Call-Queue", // this could be used to as it would be call-queue for call park else sip:....
            //             term_call_info: "held", // this would be held
            //             orig_call_info: "held" // this would be held to for call parking else active
            //         }
            //     ]
            //     this.onOff = false;
            // } else {
            //     this.onOff = true;
            // }
            let myCall = [];
            if(response.data){
                const myUser = userName.split("@")[0];
                myCall = response.data.filter((call) => (call.dnis == myUser || call.ani == myUser || call.ani == myUser+this.state.deviceType || call.dnis == this.state.callNumber || call.dnis == "1"+this.state.callNumber || "1" + call.dnis == this.state.callNumber || "1" + call.ani == this.state.callNumber|| call.dnis == "+" + this.state.callNumber || call.ani == "+" +this.state.callNumber || call.orig_uri.includes("sip:"+myUser+this.state.deviceType) || call.term_uri.includes("sip:"+myUser+this.state.deviceType)));
                callList =  response.data.filter((call) => typeof call.dnis === String && call.dnis.includes('X'));
            }
            const initials = document.querySelectorAll(".parkInitial");
            initials.forEach(initial => {
                initial.style.backgroundColor = 'MediumSeaGreen';
            });

            const parkHrefs = document.querySelectorAll(".parkHref");
            parkHrefs.forEach(parkHref => {
                parkHref.removeAttribute("href");
            });

            const others = document.querySelectorAll(".other");
            others.forEach(other => {
                other.innerHTML = null;
            });
            const myUser = userName.split("@")[0];
            for(let i=0; i<callList.length; i++){
                const parkExtension = callList[i].term_user;
                if(document.getElementById("parkInitial"+parkExtension)){
                    document.getElementById("parkInitial"+parkExtension).style.backgroundColor = "#FF0000";
                } else {
                    continue;
                }  
                if(callList[i].ani.length >= 10){
                    document.getElementById("parkOther"+parkExtension).innerHTML = callList[i].ani;
                } else {
                    document.getElementById("parkOther"+parkExtension).innerHTML = callList[i].orig_name + " - " + callList[i].ani;
                }
                
                // if(callList[i].orig_user === myUser){
                //     document.getElementById("parkOther"+parkExtension).innerHTML = callList[i].orig_name + " - " + callList[i].ani;
                // } else {
                //     document.getElementById("parkOther"+parkExtension).innerHTML = callList[i].orig_name + " - " + callList[i].ani;
                // }
                document.getElementById("parkHref"+parkExtension).setAttribute("href", "sip:"+parkExtension+"?dialAction=autoCall");
            }
            for(let i=0; i<myCall.length; i++){
                console.log("My call found with ani " + myCall[i].ani + " and dnis "+ myCall[i].dnis);
                if(myCall[i].orig_user === myUser){
                    console.log("As I am originator of call so picking term_callid to be parked");
                    
                    this.setState({myCallId : myCall[i].term_callid});
                } else {
                    console.log("As I am not the originator of call so picking orig_callid to be parked");
                    this.setState({myCallId : myCall[i].orig_callid});
                }
                
            }
            
        })
        .catch(err=>{
            console.log(err.message)
            //Toast.error(err.message);
        });
    }

    parkMe = (parkName) => {
        const hrefAttribute = document.getElementById("parkHref"+parkName)
        if((this.state.oldCallId === this.state.myCallId) || (!this.state.myCallId && !hrefAttribute.hasAttribute("href"))){
            toast.error("You are not on a call to park!");
            return;
        }
        this.setState({oldCallId: this.state.myCallId})
        let payLoad = new FormData();
        payLoad.append('uid', this.state.username);
        payLoad.append('callid', this.state.myCallId);
        payLoad.append('destination', "sip:park_"+parkName+"@"+this.state.username.split("@")[1]);
        payLoad.append('cloud_id', this.state.cloudId);
        
        const url = "?format=json&object=call&action=xfer&utype=" + this.state.uType + "&cloud_password=" +this.state.password + "&cloud_username=" + this.state.username;

        axios.post(process.env.REACT_APP_API_NS_URL + url, payLoad)
        .then(response=>{
            //this.usersInCall(this.state.uType, this.state.username, this.state.password, this.state.cloudId)
        })
        .catch(err=>{
            console.log(err.message)
            //Toast.error(err.message);
        });
    }


    getCallParkings = (uType, userName, password, cloudId) =>{
        const pullElement = document.getElementsByClassName("ptr__pull-down");
        const childElement = document.getElementsByClassName("ptr__children");
        if(pullElement.length > 0){
            pullElement[0].style.visibility = "hidden";
            childElement[0].style.transform = "none";
        }

        const payLoad = new FormData();
        payLoad.append('domain', userName.split("@")[1]);
        payLoad.append('cloud_id', cloudId);

        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=callqueue&action=read&utype=s&cloud_username=${userName}&cloud_password=${password}`, payLoad)
        .then(response=>{
            let parkingNameList = [];
            let myFilteredList = "";
            let firstTime = true;
            if(localStorage.getItem('cpFilteredListUser') && localStorage.getItem('cpFilteredListUser') === userName){
                myFilteredList = localStorage.getItem('cpFilteredList')
                firstTime = false;
            }
            
            response.data.map((row, index) => {
                if((Number(row.queue_name) >= this.state.parkingLowerLimt && Number(row.queue_name) <= this.state.parkingUpperLimt) || (Number(row.queue_name) >= this.state.parkingLowerLimt2 && Number(row.queue_name) <= this.state.parkingUpperLimt2)){
                    parkingNameList.push(row.queue_name);
                    if(firstTime){
                        myFilteredList +=  "&"+row.queue_name + "&"
                    }
                }
                return null;
            });

            
            this.createSocketConnection(this.state.engine, "https://core-testa.yovu.ca/ns-api/",this.state.user, 
            this.state.domain, this.state.accessToken, parkingNameList);

            this.callParingsRefreshed(response.data, myFilteredList);
            this.usersInCall(uType, userName, password, cloudId);
            this.setState({filteredList: myFilteredList})

            localStorage.setItem("cpFilteredList", myFilteredList);
            localStorage.setItem("cpFilteredListUser",userName);

            this.setState({callParkingsData: response.data})
        })
        .catch(err=>{
            Toast.error(err.message);
            this.setState({loading: false})
        });

    }

    callParingsRefreshed = (callParkingData, filteredList) => {
        callParkingData.sort((a, b) => {
                let fa = a.queue_name.toLowerCase(),
                    fb = b.queue_name.toLowerCase();
            
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });

            const callParkingList = callParkingData.map((row, index) => {
                if(!((Number(row.queue_name) >= this.state.parkingLowerLimt && Number(row.queue_name) <= this.state.parkingUpperLimt) || (Number(row.queue_name) >= this.state.parkingLowerLimt2 && Number(row.queue_name) <= this.state.parkingUpperLimt2))){
                    return null;
                }
                if (!filteredList.includes("&"+row.queue_name+"&")){
                    return null;
                }
                let initialsArray = row.description.split(" ")
                let initials = initialsArray[0].toString()[0];
                if(initialsArray[1]){
                    initials += initialsArray[1].toString()[0];
                }
                initials = initials.toUpperCase();
                return (
                    <div className="col-4 col-md-3 parking-container" key={"parkings"+index}>
                        <div className="user-initials" data-id={row.queue_name}>
                            <a className="parkHref" id={"parkHref"+row.queue_name} 
                                onClick={e => this.parkMe(row.queue_name)} >
                                <label className="parkInitial" id={"parkInitial"+row.queue_name}>{row.queue_name}</label>
                            </a>
                        </div>
                        <div className="user-description">
                            <p>{row.description}</p>
                            <p id={"parkOther"+row.queue_name} className="other"></p>
                        </div>
                        
                    </div>
                );
            });
            this.setState({callParkingList: callParkingList});
    } 

    componentDidMount(){

        let url = decodeURIComponent(window.location.href)

        url = url.substring(url.lastIndexOf("?username="),url.length);
        this.setState({url: url});

        let username = url.substring(url.lastIndexOf("?username=") + 10,url.lastIndexOf("&password="));
        let password = url.substring(url.lastIndexOf("&password=") + 10,url.lastIndexOf("&cloud_id="));
        let cloudId = url.substring(url.lastIndexOf("&cloud_id=") + 10,url.lastIndexOf("&direct="));
        let direct = url.substring(url.lastIndexOf("&direct=") + 8,url.length);
        cloudId = cloudId.replace("*", "")
        password = encodeURIComponent(password);
        this.setState({username: username});
        this.setState({password: password});
        this.setState({cloudId: cloudId});
        this.setState({direct: direct});
        //alert(url.searchParams.get('cloud_id'));
        let deviceType = "";
        switch (direct) {
        case "Android":
            deviceType = "m";
            break;
        case "iOS":
            deviceType = "i";
            break;
        case "Chrome":
            deviceType = "w";
            break;
        case "Win32":
            deviceType = "e";
            break;
        case "Undefined":
            deviceType = "u";
            break;
        default:
            deviceType = "d";
        }
        url = url.substring(
            url.lastIndexOf("?username="),
            url.length
        );
        this.setState({url: url});

        this.setState({deviceType: deviceType});
        // Here we will get everything based upon simple user
        let uType = 'u';

        this.getParkingLimits(cloudId);

        const payLoad = {
            user: username,
            password: password,
            cloud_id: cloudId
        }

        axios.post(`${process.env.REACT_APP_API_NS_URL}/signin`, payLoad)
        .then(response=>{
            username =  response.data.user + "@" + response.data.domain;
            this.setState({username: username});
            this.setState({uid: response.data.uid});
            this.getCallNumber(username, password, cloudId)
            this.getCallParkings(uType, username, password, cloudId);
            this.setState({uType: uType});
            this.setState({engine:response.data.engine});
            this.setState({user: response.data.user});
            this.setState({domain: response.data.domain});
            this.setState({accessToken: response.data.access_token});
        })
        .catch(err=>{
            Toast.error(err.message);
        });

       

    }

    dnsLookup = async (hostname) => {
        const url = `https://cloudflare-dns.com/dns-query?name=${hostname}&type=A`;
        try {
            const response = await axios.get(url, {
                headers: {
                'Accept': 'application/dns-json'
                }
            });
        
            const data = response.data;
            const cnameRecord = data.Answer.find(record => record.type === 5); // type 5 is CNAME
            const aRecord = data.Answer.find(record => record.type === 1); // type 1 is A
        
            return {
                canonicalName: cnameRecord ? cnameRecord.data : null,
                address: aRecord ? aRecord.name : null
            };
        } catch (error) {
            console.error('Error performing DNS lookup:', error);
            return null;
        }
    };

    createSocketConnection = async (server,failoverServer, user, domain, token, parkingNameList) => {
        let myServer = server.replace("/ns-api/", "")
        myServer = myServer.replace("https://", "")

        const result = await this.dnsLookup(myServer);
        console.log("Result for DNS lookup", result)
        //replace . at the end
        let canonicalName = result.canonicalName.replace(/\.$/, '');
        server= "https://"+canonicalName;
        if(result.canonicalName === null){
            console.log("No CNAME found", failoverServer)
            server = failoverServer.replace("/ns-api/", "")
        }
       // server = "https://" + myServer.data
        //server = server.replace("/ns-api/", "")
        // const server = "eng1-dfw.netsapiens.com" //This is the server you will be testing with. 
        // const user = "1005"; //this is the user you will be requesting data for. 
        // const domain = "portal"; //this is the domain you will be requesting data for. 
        // const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJucyIsImV4cCI6MTYzNDk0Mzk1MCwiaWF0IjoxNjM0ODU3NTUwLCJpc3MiOiJlbmcxLWRmdy5uZXRzYXBpZW5zLmNvbSIsInN1YiI6IjIwMDBAcG9ydGFsIiwidGltZV9vdXQiOjg2NDAwMDAwLCJkb21haW4iOiJwb3J0YWwiLCJ0ZXJyaXRvcnkiOiJOZXRTYXBpZW5zMiIsInVzZXIiOiIyMDAwIiwidXNlcl9lbWFpbCI6ImNhYWtlckBuZXRzYXBpZW5zLmNvbSIsInVzZXJfc2NvcGUiOiJTdXBlciBVc2VyIiwiZGlzcGxheU5hbWUiOiJQZXRlIFRoZVJlc2VsbGVyIiwiZGVwYXJ0bWVudCI6IlNhbGVzIiwibG9naW4iOiIyMDAwQHBvcnRhbCJ9.LzVvLCcfD-AcXdVUMWJz4G-FE8gTB7tpsK2WDMUJVJI"; //this is a valid access token, you will need to get it through the API yourself. (not part of this demo)

        var request_call_data = {
            domain: domain,
            filter: user,
            type: 'call',
            'bearer': token
        };

        var request_presence_data = {
            domain: domain,
            type: 'presence',
            'bearer': token
        };

        this.socket = io.connect(server + ':8001', {
            secure: true
        });

        this.socket.on('connect', () => {
            this.myLog("status", 'socket connected');
            this.myLog("status", request_call_data);
           // this.myLog("status", request_presence_data);
           this.socket.emit('subscribe', request_call_data);
           // socket.emit('subscribe', request_presence_data);

            for(let i=0; i< parkingNameList.length; i++){

                request_call_data["filter"] = parkingNameList[i];
                this.myLog("status", request_call_data);
                this.socket.emit('subscribe', request_call_data);
            }

        });

        this.socket.on('connect_error', (err) => {
        this.myLog("status", "connect_error");
        this.myLog("status", err);
        });

        this.socket.on('error', (err) =>{
            this.myLog("status", 'error');
            this.myLog("status", err);
        });

        this.socket.on('call', (data) => {
            this.myLog("call", data);
        });
        this.socket.on('presence', (data) => {
            this.myLog("presence", data);
        });

        this.socket.on('status', (data) =>{
            this.myLog("status", data);
        });
    }

    componentWillUnmount() {
        //this.socket.close()
    }

    myLog = (channel, mesg) => {
        // var table = document.getElementById("logtable");
    
        // var row = table.insertRow(1); //use 1 to skip header
        // var cell1 = row.insertCell(0);
        // var cell2 = row.insertCell(1);
        // var cell3 = row.insertCell(2);
    
        // cell1.innerHTML = new Date();
        // cell2.innerHTML = channel;
        // ------------------------------------ THIS ------------------------------
        if(channel === "call"){
            
            let callList = this.state.callList;
            let myCall = [];
            const calle = mesg
            console.log("My Call NUMBER IS ", this.state.callNumber)
            if(calle){
                const myUser = this.state.username.split("@")[0];
            
                if(calle.dnis == myUser || calle.ani == myUser+this.state.deviceType ||
                        "1" + calle.dnis == this.state.callNumber || 
                        "1" + calle.ani == this.state.callNumber|| 
                        calle.ani == myUser ||
                        calle.dnis == this.state.callNumber || 
                        calle.ani == this.state.callNumber|| 
                        calle.dnis == "1"+this.state.callNumber ||
                        calle.dnis === "+" + this.state.callNumber || 
                        calle.ani == "+" +this.state.callNumber ||
                        calle.orig_from_uri.includes("sip:"+myUser+this.state.deviceType) || 
                        calle.term_uri.includes("sip:"+myUser+this.state.deviceType)){
                    myCall.push(calle);
                }

                if(calle.dnis.includes('X') && !calle.hasOwnProperty('remove')){
                    callList.push(calle);
                    this.setState({callList: callList});
                } else if (calle.dnis.includes('X') && calle.hasOwnProperty('remove')){
                    callList = callList.filter((item) => {
                        return item.orig_callid !== calle.orig_callid;
                    });
                    this.setState({callList: callList});
                }
            }
            const initials = document.querySelectorAll(".parkInitial");
            initials.forEach(initial => {
                initial.style.backgroundColor = 'MediumSeaGreen';
            });

            const parkHrefs = document.querySelectorAll(".parkHref");
            parkHrefs.forEach(parkHref => {
                parkHref.removeAttribute("href");
            });

            const others = document.querySelectorAll(".other");
            others.forEach(other => {
                other.innerHTML = null;
            });
            const myUser = this.state.username.split("@")[0];
            this.callParingsRefreshed(this.state.callParkingsData, this.state.filteredList);
            for(let i=0; i<callList.length; i++){
                const parkExtension = callList[i].term_user;
                if(document.getElementById("parkInitial"+parkExtension)){
                    document.getElementById("parkInitial"+parkExtension).style.backgroundColor = "#FF0000";
                } else {
                    continue;
                }  
                if(callList[i].ani.length >= 10){
                    document.getElementById("parkOther"+parkExtension).innerHTML = callList[i].ani;
                } else {
                    document.getElementById("parkOther"+parkExtension).innerHTML = callList[i].orig_name + " - " + callList[i].ani;
                }
                
                // if(callList[i].orig_user === myUser){
                //     document.getElementById("parkOther"+parkExtension).innerHTML = callList[i].orig_name + " - " + callList[i].ani;
                // } else {
                //     document.getElementById("parkOther"+parkExtension).innerHTML = callList[i].orig_name + " - " + callList[i].ani;
                // }
                document.getElementById("parkHref"+parkExtension).setAttribute("href", "sip:"+parkExtension+"?dialAction=autoCall");
            }
            for(let i=0; i<myCall.length; i++){
                console.log("My call found with ani " + myCall[i].ani + " and dnis "+ myCall[i].dnis);
                if(myCall[i].orig_user === myUser){
                    console.log("As I am originator of call so picking term_callid to be parked");
                    this.setState({myCallId : myCall[i].term_callid});
                } else {
                    console.log("As I am not the originator of call so picking orig_callid to be parked");
                    this.setState({myCallId : myCall[i].orig_callid});
                }
                
            }
        } 

        if(channel === "presence"){
            const presenceData = mesg;
            console.log("--------in presecne---------------------")
            console.log("presenceData.user", presenceData.user)
            console.log("this.state.parkingLowerLimt", this.state.parkingLowerLimt)
            console.log("this.state.parkingUpperLimt", this.state.parkingUpperLimt)
            if(((Number(presenceData.user) >= this.state.parkingLowerLimt && 
                Number(presenceData.user) <= this.state.parkingUpperLimt) || (Number(presenceData.user) >= this.state.parkingLowerLimt2 && 
                Number(presenceData.user) <= this.state.parkingUpperLimt2)) && presenceData.presence === "held"){
                    document.getElementById("parkInitial"+presenceData.user).style.backgroundColor = "#FF0000";
            } else if (((Number(presenceData.user) >= this.state.parkingLowerLimt && 
            Number(presenceData.user) <= this.state.parkingUpperLimt) || (Number(presenceData.user) >= this.state.parkingLowerLimt2 && 
            Number(presenceData.user) <= this.state.parkingUpperLimt2)) && presenceData.presence === "inactive"){
                document.getElementById("parkInitial"+presenceData.user).style.backgroundColor = "mediumseagreen";
            }
        }
        //--------------------------------------------------------------------------
        // if (typeof mesg == "object")
        //     cell3.innerHTML = "<pre>" + this.syntaxHighlight(JSON.stringify(mesg, undefined, 4)) + "</pre>";
        // else
        //     cell3.innerHTML = "<pre>" + this.syntaxHighlight(mesg) + "</pre>";
    }
    // syntaxHighlight = (json) => {
    //     json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    //     return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function(match) {
    //         var cls = 'number';
    //         if (/^"/.test(match)) {
    //         if (/:$/.test(match)) {
    //             cls = 'key';
    //         } else {
    //             cls = 'string';
    //         }
    //         } else if (/true|false/.test(match)) {
    //         cls = 'boolean';
    //         } else if (/null/.test(match)) {
    //         cls = 'null';
    //         }
    //         return '<span class="' + cls + '">' + match + '</span>';
    //     });
    // }

    getParkingLimits = (cloudId) => {
        let payLoad = {
            cloud_id: cloudId,
        };
        axios.post(`${process.env.REACT_APP_API_URL}getParkingLimits`, payLoad)
        .then(response => {
            this.setState({ parkingLowerLimt: response.data.park_lower_limit });
            this.setState({ parkingUpperLimt: response.data.park_upper_limit });
            this.setState({ parkingLowerLimt2: response.data.park_lower_limit2 });
            this.setState({ parkingUpperLimt2: response.data.park_upper_limit2 });
            this.setState({style: 'custom-spinner-hide'});
        }).catch(err => {
            //toast.error(err.response.data);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    getCallNumber = (userName, password, cloudId) => {
        const payLoad = new FormData();
        payLoad.append('domain', userName.split("@")[1]);
        payLoad.append('user', userName.split("@")[0]);
        payLoad.append('cloud_id', cloudId);

        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=subscriber&action=read&utype=u&cloud_username=${userName}&cloud_password=${password}`, payLoad)
        .then(response=>{
            this.setState({callNumber: response.data[0].callid_nmbr});
            //console.log("sms number"+ response.data[0].number)
        }).catch(err => {
            toast.error("Failed to your sms number");
        
        });
    }

    toggleHandler = () => {
        this.setState({filterModal: !this.state.filterModal});
    };

    filterOnChnageHandler = (evt) => {
        let myList = this.state.filteredList;
        if(evt.target.checked){
            //console.log("add "+evt.target.name)
            
            myList += "&"+evt.target.name + "&"
        } else {
            //console.log("remove "+evt.target.name)
            myList = myList.replace("&"+evt.target.name+"&","")
            
        }
        this.setState({filteredList: myList})
        localStorage.setItem("cpFilteredList", myList);
        this.callParingsRefreshed(this.state.callParkingsData, myList);
    }
    render() {


        return(
            <Auxi>
                <AppLayout cloud_id={this.state.cloudId}
                    display_name = {this.state.username}
                    fetchMessages={this.fetchMessagesHandler}
                    ownContact = {this.state.ownContact}
                    url={this.state.url}
                    fromPage = "callPark"/>

                <div className = "CustomMainIndexApp">
                    {
                        this.state.callParkingsData?
                            <FilterModal
                                show={this.state.filterModal}
                                filteredList={this.state.filteredList}
                                callParkingsData={this.state.callParkingsData}
                                parkingLowerLimt={this.state.parkingLowerLimt}
                                parkingUpperLimt={this.state.parkingUpperLimt}
                                parkingLowerLimt2={this.state.parkingLowerLimt2}
                                parkingUpperLimt2={this.state.parkingUpperLimt2}
                                onChangeHandler={this.filterOnChnageHandler}
                                toggleHandler={this.toggleHandler}/>
                            : null
                    }
                    

                    <div className="row call-parking-layout">
                        <div className="col-xs-0 col-md-2"> </div>
                        <div className = "col-xs-12 col-md-8">
                           
                            <PullToRefresh
                                onRefresh={e => this.getCallParkings(this.state.uType, this.state.username, this.state.password, this.state.cloudId)}
                            >
                                <div className="row">
                                <div className="col-md-12">
                                    {
                                        this.state.callParkingsData?
                                            <button className="btn btn-dark" type="button" 
                                                onClick={this.toggleHandler} style={{width:"100px"}}>
                                                Filter
                                            </button>
                                        : null
                                    }
                                    
                                </div>
                                    {this.state.callParkingList}
                                
                                    {/* goes here */}
                                    {/* <div className="col-4 col-md-3 parking-container">
                                        <div className="user-initials">
                                            <div>
                                                <label>P1</label>
                                            </div>
                                        </div>
                                        <div className="user-description">
                                            <p>Parking 1</p>
                                            <p className="other">Daniyal Majeed - 120</p>
                                        </div>
                                        
                                    </div>
                                    <div className="col-4 col-md-3 parking-container">
                                        <div className="user-initials">
                                            <div>
                                                <label>P2</label>
                                            </div>
                                        </div>
                                        <div className="user-description">
                                            <p>Parking 2</p>
                                            <p className="other">Daniyal Majeed - 120</p>
                                        </div>
                                        
                                    </div>
                                    <div className="col-4 col-md-3 parking-container">
                                        <div className="user-initials">
                                            <div>
                                                <label>P3</label>
                                            </div>
                                        </div>
                                        <div className="user-description">
                                            <p>Parking 3</p>
                                            <p className="other">Daniyal Majeed - 120</p>
                                        </div>
                                        
                                    </div>
                                    <div className="col-4 col-md-3 parking-container">
                                        <div className="user-initials">
                                            <div>
                                                <label style={{backgroundColor: "#FF0000"}}>P4</label>
                                            </div>
                                        </div>
                                        <div className="user-description">
                                            <p>Parking 4</p>
                                            <p className="other">Daniyal Majeed - 120</p>
                                        </div>
                                        
                                    </div> */}
                                
                                
                                </div>
                                {/* <div bgcolor="#FFFFFF">
                                    <hr size="1" noshade/> New messages at the top...
                                    <br/>
                                    <br/>
                                    <table id="logtable" border="1" bordercolor="#224466" width="100%">
                                    <tr>
                                        <th>Time</th>
                                        <th>Channel</th>
                                        <th>Message</th>
                                    </tr>

                                    </table>
                                    <br/>
                                </div> */}
                            </PullToRefresh>
                        </div>
                        <div className="col-xs-0 col-md-2"></div>
                    </div>
                </div>
            </Auxi>

        );

    }

}

export default CallParkings;
